<template>
  <div>
    <lottie-vue-player :src="`https://assets8.lottiefiles.com/packages/lf20_yCjSOH1fA9.json`"
      :theme="options.theme"
      :player-size="options.playerSize"
      :player-controls="false"
      :autoplay="true"
      :loop="true"
      style="width: 100%; height:400px">
    </lottie-vue-player>
  </div>
</template>


<script>
export default {
        name: 'NotFound',
        data() {
            return {
                options: {
                    minimizable: false,
                    playerSize: "standard",
                    backgroundColor: '#fff',
                    backgroundStyle: 'color',
                    theme: {
                        controlsView: "standard",
                        active: "light",
                        light: {
                            color: '#3D4852',
                            backgroundColor: '#fff',
                            opacity: '0.7',
                        },
                        dark: {
                            color: '#fff',
                            backgroundColor: '#202020',
                            opacity: '0.7',
                        }
                    }
                }
            }
        }
    }


</script>

