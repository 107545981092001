export default {
    namespaced: true,

    state: () => {
        return {
            loadingRepair: false,
            repairs: [],
            invoices: [],
        }
    },

    getters: {
        loadingRepair(state) {
            return state.loadingRepair;
        },

        repairs(state) {
            return state.repairs.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },

        invoices(state) {
            return state.invoices;
        },

    },

    mutations: {
        loadingRepair(state, isLoading) {
            state.loadingRepair = isLoading;
        },

        setRepairs(state, repairs) {
            state.repairs = repairs;
        },

        setInvoice(state, invoices) {
            state.invoices = invoices;
        },
    },

    actions: {
        async getRepairs(context, payload) {
            context.commit('loadingRepair', true);

            let repairs = await axios.post(`${this.state.host}/get-repairs`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.repairs;

            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })

            context.commit('setRepairs', repairs);

            context.commit('loadingRepair', false);
        },

        async saveRepair(context, payload) {
            let response = { isSuccess: false};

            let url = '';

            if(payload.repair.id != null) {
                url = 'update-repair';
            } else {
                url = 'add-repair';
                delete payload.repair.id;
            }

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                response.isSuccess = true;
                response.id = res.data.id;
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            });

            return response;
        },

        async deleteRepair(context, id) {
            await axios.delete(`${this.state.host}/delete-repair/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.dispatch('getRepairs');
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            });
        },

        async generateRepairCode(context) {
            let newCode = await axios.get(`${this.state.host}/generate-repair-code`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.invoice;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error)
            })

            return newCode;
        },
        
        async getInvoices(context) {
            let invoice = await axios.get(`${this.state.host}/get-repair-invoices`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.invoices;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error)
            })
    
            context.commit('setInvoice', invoice);
        },

    }
}